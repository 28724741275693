const sidenav = [
  {
    name: 'Client',
    subName: 'onboarding',
    activeRule: '/onboarding/client',
    active: true,
    iconPath: 'images/User_cicrle_duotone_line.png'
  },
  {
    name: 'Masters',
    subName: 'masters',
    activeRule: '/masters/',
    active: true,
    iconPath: 'images/database_duotone.png',
    children: [
      {
        name: 'TDS Section Master',
        subName: 'nopratemaster',
        activeRule: '/masters/tds/nopratemaster'
      },
      {
        name: 'TCS Section Master',
        subName: 'nocratemaster',
        activeRule: '/masters/tds/nocratemaster'
      },
      {
        name: 'Reasoning',
        subName: 'reasoning',
        activeRule: '/masters/tds/reasoning'
      }
    ]
  }
  // {
  //   name: 'User Roles',
  //   subName: 'userroles',
  //   activeRule: '/userroles/role',
  //   active: true,
  //   iconPath: 'images/Lock_duotone_line.png'
  // }
]

export default sidenav
