import App from './App.vue'
import { createApp } from 'vue'
import { registerMicroApps, start, setDefaultMountApp } from 'qiankun'
import microApps from './micro-app'
import 'nprogress/nprogress.css'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import '../index.less'
import NProgress from 'nprogress'

NProgress.configure({
  minimum: 0.08,
  easing: 'linear',
  positionUsing: '',
  speed: 200,
  trickle: true,
  trickleSpeed: 200,
  showSpinner: true,
  barSelector: '[role="bar"]',
  spinnerSelector: '[role="spinner"]',
  parent: 'body',
  template: '<div class="bar" style="z-index: 1032;height: 3px" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner" style=" width: 100%; height: 100%; background: rgba(255, 255, 255, 0.5); position: fixed; top: 0; padding-top: 45vh; "><div style="width: 64px;height: 64px;background-image: url(/images/loader.gif);background-repeat: no-repeat;margin: auto;"></div></div>'
})

NProgress.start()
const vueApp = createApp(App)
vueApp.use(Antd)
vueApp.mount('#app')
function loader (loading) {
  if (vueApp._instance) {
    vueApp._instance.props.loading = loading
  }
}

async function getMicroapps () {
  const microApp = await microApps()
  console.log(microApp)

  const apps = microApp.map(item => {
    return {
      ...item,
      loader
    }
  })

  registerMicroApps(apps, {
    beforeLoad: app => {
      console.log('before load app.name====>>>>>', app.name)
      NProgress.start()
    },
    beforeMount: [
      app => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
        NProgress.start()
      }
    ],
    afterMount: [
      app => {
        console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
        NProgress.done()
      }
    ],
    afterUnmount: [
      app => {
        console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
        NProgress.start()
      }
    ]
  })
  setDefaultMountApp('/dashboard/')
  start()
}

getMicroapps()
