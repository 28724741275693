
const headers = [
  // {
  //   name: 'Dashboard',
  //   subName: 'dashboard',
  //   activeRule: '/dashboard/'
  // },
  {
    name: 'TDS',
    subName: 'tds',
    activeRule: ''
  },
  {
    name: 'TCS',
    subName: 'tcs',
    activeRule: ''
  },
  {
    name: 'Clause 34 Reconciliation',
    subName: 'clause34',
    activeRule: ''
  },
  {
    name: 'Form 26AS vs. Income Reconciliation',
    subName: 'form26AS',
    activeRule: ''
  }
]

export default headers
