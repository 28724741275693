<template>
  <div class="layout-wrapper">
    <a-layout-header class="header">
      <div class="logo">
        <MenuOutlined
            style="color: #fff; position: relative; top: 3px;"
            class="trigger"
            @click="() => (collapsed = !collapsed)"
          />
        <img :src="`${publicPath}images/logo.png`" alt="" srcset="" style="margin-left: 10px;">
      </div>
      <div class="notifi-block" >
        <span class="user-avatar">
          <img :src="`${publicPath}images/Avatar.png`" alt="user" style="mragin-right: 10px; display: inline-block;" />
          <sub class="user-active"></sub>
        </span>
        <a-dropdown :trigger="['click']" :style="{display: 'inline-block'}">
        <a class="ant-dropdown-link" @click.prevent :style="{color: '#fff'}">
          {{ loggedinUserInfo?.userName }}
          <span>{{ loggedinUserInfo?.roleName }}</span>
          <DownOutlined />
        </a>
        <template #overlay :style="{width: '250px', right: '0'}">
          <a-menu>
            <a-menu-item key="3"  @click="logout()">Logout</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      </div>
    </a-layout-header>
    <a-layout style="min-height: 100vh;padding-top:64px">
      <a-layout-sider :collapsed="collapsed" :trigger="null" collapsible>
        <a-menu theme="dark" mode="inline"
          :openKeys="openKeys"
          :selectedKeys="selectedKeys"
          @openChange="onOpenChange">
          <template v-for="menu in sidenav">
            <template v-if="menu.active">
              <template v-if="!menu.children">
                <template v-if="menu.activeRule.includes(menu.subName)">
                  <a-menu-item :key="menu.subName" @click="goto(menu)">
                    <template #icon><img :src="`${publicPath}${menu.iconPath}`"></template>
                    {{ menu.name }}
                  </a-menu-item>
                </template>
              </template>
              <!-- <template v-else>
                <a-sub-menu :key="menu.subName">
                  <template #icon><img :src="`${publicPath}${menu.iconPath}`"></template>
                  <template #title>{{ menu.name }}</template>
                  <template  v-for="subMenu in menu.children">
                    <template v-if="subMenu.activeRule.includes(selectedHeader)">
                      <a-menu-item :key="subMenu.subName" @click="gotoSubMenu(subMenu)">
                        {{ subMenu.name }}
                      </a-menu-item>
                    </template>
                  </template>
                </a-sub-menu>
              </template> -->
              <template v-else>
                <a-sub-menu :key="menu.subName">
                  <template #icon><img :src="`${publicPath}${menu.iconPath}`"></template>
                  <template #title>{{ menu.name }}</template>
                  <a-menu-item v-for="subMenu in menu.children" :key="subMenu.subName" @click="gotoSubMenu(subMenu)">
                    {{ subMenu.name }}
                  </a-menu-item>
                </a-sub-menu>
              </template>
            </template>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout class="main-section">
        <a-layout-content
          :style="{ minHeight: '280px' }"
        >
          <div id="subapp-viewport"></div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuOutlined,
  DownOutlined
} from '@ant-design/icons-vue'
import { defineComponent, reactive, toRefs, ref } from 'vue'
import headers from './header'
import sidenav from './sidenav'
import userInfo from './userInfo'

export default defineComponent({
  setup () {
    const visible = ref(false)
    const loggedinUserInfo = ref({})
    const showModal = () => {
      visible.value = true
    }
    const handleOk = (e) => {
      console.log(e)
      visible.value = false
    }
    const handleCancel = (e) => {
      console.log(e)
      visible.value = false
    }
    const state = reactive({
      rootSubmenuKeys: sidenav.map(item => item.subName),
      openKeys: [window.location.pathname?.split('/')[1]],
      selectedKeys: [(window.location.pathname?.split('/')[3]) ? window.location.pathname?.split('/')[3] : window.location.pathname?.split('/')[1]]
    })
    const onOpenChange = (openKeys) => {
      const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1)
      if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : []
        state.selectedKeys = []
      }
    }
    async function getUserDetails () {
      const userDetails = await userInfo()
      if (userDetails.validUser) {
        loggedinUserInfo.value = userDetails.loggedinUserInfo
      }
    }
    getUserDetails()
    return {
      ...toRefs(state),
      onOpenChange,
      collapsed: ref(false),
      visible,
      showModal,
      handleOk,
      handleCancel,
      loggedinUserInfo
    }
  },
  components: {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    MenuOutlined,
    DownOutlined
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      isLoading: true,
      headersMenu: [],
      sidenav,
      current: '/dashboard/'
    }
  },
  computed: {},
  methods: {
    goto (item) {
      history.pushState(null, item.activeRule, item.activeRule)
      this.selectedKeys = [item.subName]
      this.openKeys = []
    },
    gotoSubMenu (item) {
      history.pushState(null, item.activeRule, item.activeRule)
      this.selectedKeys = [item.subName]
    },
    gotoHeader (item) {
      // history.pushState(null, item.activeRule, item.activeRule)
    },
    logout () {
      fetch(`${localStorage.getItem('identityServiceUrl')}api/identity-service/admin/logout?key=${localStorage.getItem('loginKey')}`, {
        method: 'DELETE'
      }).then(response => response.json())
        .then(redisInfo => {
          window.location.href = `${localStorage.getItem('identityServiceUrl')}Account/Login`
          localStorage.clear()
        })
        .catch(error => {
          console.log(error)
        })
    },
    bindCurrent () {
      const path = window.location.pathname
      const index = this.sidenav.findIndex(item => item.activeRule === path)
      if (index >= 0) {
        this.current = path
      }
      setTimeout(() => {
        this.headersMenu = []
        const projectRoute = (this.openKeys[0]) ? this.openKeys[0] : this.selectedKeys[0]
        headers.forEach((header) => {
          header.activeRule = '/' + projectRoute + '/' + header.subName
          this.headersMenu.push(header)
        })
      }, 1000)
      this.openKeys = [window.location.pathname?.split('/')[1]]
      this.selectedKeys = [(window.location.pathname?.split('/')[3]) ? window.location.pathname?.split('/')[3] : window.location.pathname?.split('/')[1]]
    },
    listenRouterChange () {
      const _wr = function (type) {
        const orig = history[type]
        return function () {
          const rv = orig.apply(this, arguments)
          const e = new Event(type)
          e.arguments = arguments
          window.dispatchEvent(e)
          return rv
        }
      }
      history.pushState = _wr('pushState')

      window.addEventListener('pushState', this.bindCurrent)
      window.addEventListener('popstate', this.bindCurrent)
    }
  },
  created () {
    this.bindCurrent()
  },
  mounted () {
    this.listenRouterChange()
  }
})
</script>

<style lang="scss">
html, body{
  margin: 0 !important;
  padding: 0;
}
.ant-layout-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000 !important;
  padding: 15px !important;
  z-index: 100;
}
.ant-layout-header .logo {
  float: left;
  width: 245px;
  line-height: normal;
}
.ant-layout-header .ant-menu-dark {
  background: transparent !important;
  float: left;
  width: 58%;
  line-height: 1px !important;
  padding-left: 90px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.ant-layout-header .ant-menu-dark .ant-menu-item {
  color: rgba(255, 255, 255, 0.8) !important;
  padding: 6px 20px !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-right: 12px;
  border: 0 !important;
}
.ant-layout-header .ant-menu-dark .ant-menu-item:hover {
  background-color: transparent !important;
}
.ant-layout-header .ant-menu-horizontal > .ant-menu-item::after {
  right: auto;
  left: 0;
  bottom: auto;
  top: -14px;
  border-bottom: 0px solid transparent !important;
}
.ant-layout-header .ant-menu-dark .ant-menu-item:hover::after {
  top: -14px;
  left: 0;
  width: 100%;
  height: 2px;
  border: 0 !important;
  background: #86F200;
}
.ant-layout-header .ant-menu.ant-menu-dark .ant-menu-item-selected {
  color: #fff !important;
  background-color: transparent !important;
}
.ant-layout-header  .ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected::after {
  top: -14px;
  left: 0;
  width: 100%;
  height: 2px;
  border: 0 !important;
  background: #86F200;
}
.ant-layout-sider {
  flex: 0 0 215px !important;
  max-width: 215px !important;
  min-width: 215px !important;
  width: 215px !important;
  background: #E5EAEC !important;
  box-shadow: 0px 4px 60px rgb(160 164 168 / 30%);
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.ant-layout-sider.ant-layout-sider-collapsed{
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important
}
.ant-layout-sider.ant-layout-sider-collapsed + .ant-layout.main-section {
  margin-left: 80px;
}
.ant-layout-sider .ant-menu.ant-menu-dark {
  background: #E5EAEC !important;
}
.ant-layout-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #E5EAEC !important;
  padding-left: 12px;
}
.ant-layout-sider .ant-menu-dark .ant-menu-item {
  color: #2F343B !important;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  font-weight: 400;
}
.ant-layout-sider .ant-menu-submenu-title {
  color: #2F343B !important;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  font-weight: 400;
}
.ant-layout-sider .ant-menu-submenu-open .ant-menu-submenu-title{
  background: linear-gradient(90.05deg, #86F200 1.54%, rgba(134, 242, 0, 0) 99.96%);
  color: #000 !important;
  font-weight: 600;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span {
  color: #000 !important;
}
.ant-menu-dark .ant-menu-item-selected .anticon+span {
  color: #000 !important;
}
.ant-menu-dark .ant-menu-item-selected:hover>.ant-menu-title-content{
  font-weight: 600 !important;
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected.ant-menu-item-selected {
  background: linear-gradient(90.05deg, #86F200 1.54%, rgba(134, 242, 0, 0) 99.96%);
  color: #000 !important;
  font-weight: 600;
}
.ant-layout-sider .ant-menu.ant-menu-sub .ant-menu-item.ant-menu-item-selected.ant-menu-item-selected {
  background: transparent;
}
.ant-layout-sider .ant-menu-dark .ant-menu-item-selected .anticon {
  color: #000 !important;
}
.ant-layout-sider .ant-menu-dark .ant-menu-item-selected .anticon+span  {
  color: #000 !important;
}
.ant-layout-sider .ant-menu-submenu-arrow {
  color: #000 !important;
}
.ant-layout-sider .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #000 !important;
}
.ant-layout-sider .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: #000 !important;
}
.ant-layout-sider .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #2F343B !important;
}
.ant-layout-sider .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #2F343B !important;
}
.ant-layout-sider .ant-menu-dark.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
  color: #000 !important;
  font-weight: 600;
}
.ant-layout-sider .ant-menu-dark.ant-menu-dark .ant-menu-item:hover {
  font-weight: 500;
}
.ant-layout.main-section {
  background: linear-gradient(180deg, #E5EAEC 33.44%, #E5EAEC 100%);
}
.notifi-block {
  float: right;
  max-width: 18%;
  vertical-align: middle;
  margin-top: -15px;
}
.notifi-count {
  position: absolute;
  top: 22px;
  right: -2px;
  background: #F74141;
  padding: 3px 4px;
  border-radius: 100%;
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  display: block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  line-height: 10px;
}
.user-avatar {
  display: inline-block;
  margin-right: 7px;
  position: relative;
}
.user-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.user-active {
  position: absolute;
  bottom: 14px;
  background: #009A44;
  right: 0;
  width: 9px;
  height: 9px;
  border-radius: 100%;
}
.ant-dropdown {
  width:222px !important;
}
.ant-dropdown-link {
  font-size: 12px;
  font-weight:400;
}
.ant-dropdown-link .anticon {
  padding-left: 15px;
}
.ant-dropdown-link .anticon::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
}
.ant-dropdown-link .anticon svg {
  display: none;
}
.ant-layout-sider {
  position: fixed !important;
}
.ant-layout.main-section {
  margin-left: 215px;
  padding-left: 10px;
}
.ant-modal.modal-no-footer .ant-modal-footer,
.ant-modal.modal-no-header .ant-modal-header {
    display: none;
}
.notifi-modal {
  background: #fff;
}
.notifi-modal small.heading {
  font-size: 14px;
  font-weight: 500;
  color: #1A1F36;
  display: block;
  padding: 12px 16px;
}
.notifi-modal ul {
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
  background: #fff;
}
.notifi-modal ul:after, .notifi-modal ul:before {
  content: "";
  display:table;
  clear:both;
}
.notifi-modal li {
  list-style: none;
  padding: 8px 16px 16px;
  border-bottom: 1px solid #E4E8EE;
  float: left;
  width: 100%;
}
.notifi-modal li:after, .notifi-modal li:before {
  content: "";
  display:table;
  clear:both;
}
.noti-avatar {
  display:block;
  width:32px;
  height:32px;
  margin-right: 16px;
  background: #00A3E0;
  color: #fff;
  font-size:16px;
  font-weight:600;
  line-height:32px;
  text-align:center;
  border-radius: 100%;
  float: left;
}
.notifi-modal ul li:nth-child(2) .noti-avatar {
  background:rgba(0,0,0,0.7);
}
.noti-text {
  float: left;
  width:88%;
}
.noti-text h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2F343B;
  margin-bottom: 16px;
}
.noti-text h6 .rejected {
  color: #FC5A5A;
}
.noti-text h6 .approved {
  color: #74C911;
}
.noti-text h6 strong {
  color: #1A1F36;
}
.noti-text small {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #A5ACB8;
}
</style>
