// import store from './store'

const microApps = [
  {
    name: 'Onboarding',
    subName: 'onboardinG_APP',
    entry: null,
    activeRule: '/onboarding/'
  },
  {
    name: 'Masters',
    subName: 'masterS_APP',
    entry: null,
    activeRule: '/masters/',
    active: true
  },
  {
    name: 'User Roles',
    subName: 'userroleS_APP',
    entry: null,
    activeRule: '/userroles/',
    active: true
  }
]
const fetchProps = async () => {
  const identityServiceUrl = document.referrer
  return new Promise(resolve => {
    if (localStorage.getItem('propsInfoList') == null) {
      localStorage.setItem('identityServiceUrl', identityServiceUrl)
      fetch(`${identityServiceUrl}api/identity-service/admin/props`)
        .then(response => response.json())
        .then(data => {
          localStorage.setItem('adminPropsInfo', data.apiGatewayRestURL)
          localStorage.setItem('propsInfoList', JSON.stringify(data))
          const apps = microApps.map(item => {
            item.entry = data[item.subName]
            return {
              ...item,
              container: '#subapp-viewport', // 子应用挂载的div
              props: {
                routerBase: item.activeRule // 下发基础路由
                // getGlobalState: store.getGlobalState // 下发getGlobalState方法
              }
            }
          })
          resolve(apps)
        })
        .catch(error => console.error(error))
    } else {
      const data = JSON.parse(localStorage.getItem('propsInfoList')) || {}
      const apps = microApps.map(item => {
        item.entry = data[item.subName]
        return {
          ...item,
          container: '#subapp-viewport', // 子应用挂载的div
          props: {
            routerBase: item.activeRule // 下发基础路由
            // getGlobalState: store.getGlobalState // 下发getGlobalState方法
          }
        }
      })
      resolve(apps)
    }
  })
}

export default fetchProps
