
const urlParams = new URLSearchParams(window.location.search)
const sessionKey = urlParams.get('key')
let key
if (sessionKey) {
    key = sessionKey
    localStorage.setItem('loginKey', key)
} else {
    key = localStorage.getItem('loginKey')
}
const userInfo = async () => {
    return new Promise(resolve => {
        const identityServiceUrl = document.referrer
        if (!localStorage.getItem('LoggedinUserId')) {
            fetch(`${identityServiceUrl}api/identity-service/admin/getredisinfo?key=${key}`)
                .then(response => response.json())
                .then(redisInfo => {
                    localStorage.setItem('LoggedinUserId', redisInfo[0].loginId)
                    localStorage.setItem('userInfo', JSON.stringify(redisInfo[0]))
                    const response = {
                        validUser: true,
                        loggedinUserInfo: JSON.parse(localStorage.getItem('userInfo')) || {}
                    }
                    resolve(response)
                })
        } else {
            const response = {
                validUser: true,
                loggedinUserInfo: JSON.parse(localStorage.getItem('userInfo')) || {}
            }
            resolve(response)
        }
    })
}

export default userInfo
